import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
const root = ReactDOM.createRoot(document.getElementById("root"));
const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};
if (window.location.href.indexOf("/connect/metamask") == -1) {
  const loadingScreen = document.getElementById("pre_loader_st");
  loadingScreen.classList.remove("hide");
}
document.addEventListener("DOMContentLoaded", function (event) {
  root.render(
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </React.StrictMode>
  );
  if (window.location.href.indexOf("/connect/metamask") == -1) {
    const loadingScreen = document.getElementById("pre_loader_st");
    setTimeout(() => {
      loadingScreen.classList.add("hide");
      document.querySelector("body").classList.remove("overflow-hidden");
    }, 3000);
  }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
