import React from "react"
import GlobalStyle from "./components/GlobalStyle"
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import { MetaMaskWallet } from "./components/home/CryptoModals"

const App = () => {
  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/connect/metamask" component={MetaMaskWallet} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;
