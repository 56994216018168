import React, { useState , useEffect , useRef } from "react"
import styled from "styled-components"
import { WalletConnect, BinanceWallet, MathWallet, CoinBaseWallet, SafePalWallet, MetaMaskWallet } from "./CryptoModals"
// import { useWeb3React } from "@web3-react/core";
// import { Connectors } from "../wallet/Connectors";
import {
  MetaMask,
  walletconnect,
  binanceConnect,
  mathWallet,
  coinbase,
  safepal,
  MoreImg,
} from "../Images"
import { createPortal } from "react-dom";
const RenderInWindow = ({openData,children}) => {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(window);
  const host = window.location.protocol + "//" + window.location.host;
  useEffect(() => {
    const div = document.createElement("div");
    setContainer(div);
  }, []);
  useEffect(() => {
    if (container) {
      newWindow.current = window.open(
        host+"/connect/metamask",
        "",
        "width=400,height=679,left=200,top=200"
      );
      newWindow.current.document.body.appendChild(container);
      const curWindow = newWindow.current;
      const intd = setInterval(()=>{
        if (curWindow.closed) { 
          openData(false);
          clearInterval(intd);
        }
      },100)
      return () => curWindow.close();
    }

  }, [container]);

  return container && createPortal(children, container);
};

const ConnectModal = ({ setConnectModal }) => {
  const [walletConnectState, setWalletConnectState] = useState(false)
  const [binanceWallet, setBinanceWallet] = useState(false)
  const [mathWalletState, setMathWallet] = useState(false)
  const [coinbaseState, setCoinbaseWallet] = useState(false)
  const [safePalState, setSafePalWallet] = useState(false)
  const [metaMaskState, setMetaMaskWallet] = useState(false)
  const [open, setOpen] = useState();
  // const [walletAddress, setWalletAddress] = useState("");
  // const { activate } = useWeb3React();
  // const setProvider = (type) => {
  //   window.localStorage.setItem("provider", type);
  // };
  // // Requests access to the user's META MASK WALLET
  // // https://metamask.io
  // async function requestAccountMetaMask() {
  //   console.log('Requesting account...');

  //   // ❌ Check if Meta Mask Extension exists on the user's browser
  //   if (window.ethereum) {
  //     console.log("detected");

  //     try {
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });
  //       setWalletAddress(accounts[0]);
  //     } catch (error) {
  //       console.log("Error connecting...");
  //     }
  //   } else {
  //     alert("Meta Mask not detected");
  //   }
  // }

  // // Requests access to the user's Binance smart chain WALLET
  // async function requestAccountBinance() {
  //   console.log('Requesting account...');

  //   // ❌ Check if Meta Mask Extension exists on the user's browser
  //   if (window.BinanceChain) {
  //     console.log('detected');

  //     try {
  //       const accounts = await window.BinanceChain.request({
  //         method: "eth_requestAccounts",
  //       });
  //       setWalletAddress(accounts[0]);
  //     } catch (error) {
  //       console.log('Error connecting...');
  //     }

  //   } else {
  //     alert('Binance smart chain wallet not detected');
  //   }
  // }
  const getStatus = (status) => {
    setOpen(status);
  }
  return (
    <>
      <BackDrop onClick={() => setConnectModal(false)} />
      <ModalStyled>
        <div className="connect-header">
          <span onClick={() => setConnectModal(false)}>&times;</span>
        </div>
        <div className="content">
          <div className="wallets-wrapper">
            {/* onClick={() => {
              activate(Connectors.injected);
              setProvider("injected");
            }} */}
            <div className="wallet-box" onClick={() => {setOpen(true);}}>
              <MetaMask />
              <p>MetaMask</p>
              <span>Connect to your MetaMask Wallet</span>
            </div>
            {open && <RenderInWindow openData={getStatus} />}
            {/* onClick={() => {
              activate(Connectors.walletConnect);
              setProvider("walletConnect");
            }} */}
            <div className="wallet-box" onClick={() => setWalletConnectState(true)}>
              <img src={walletconnect} alt="walletConnect" />
              <p>WalletConnect</p>
              <span>Scan with WalletConnect to connect</span>
            </div>
            {/* onClick={requestAccountBinance} */}
            <div className="wallet-box" onClick={() => setBinanceWallet(true)}>
              <img src={binanceConnect} alt="binance-connect" />
              <p>Binance</p>
              <span>Binance Chain Wallet</span>
            </div>
            <div className="wallet-box" onClick={() => setMathWallet(true)}>
              <img src={mathWallet} alt="mathWallet" />
              <p>Math</p>
              <span>Math Wallet</span>
            </div>
            {/* onClick={() => {
              activate(Connectors.coinbaseWallet);
              setProvider("coinbaseWallet");
            }} */}
            <div className="wallet-box" onClick={() => setCoinbaseWallet(true)}>
              <img src={coinbase} alt="trustWallet" />
              <p>Coinbase</p>
              <span>Coinbase Wallet</span>
            </div>
            <div className="wallet-box" onClick={() => setSafePalWallet(true)}>
              <img src={safepal} alt="safepal" />
              <p>SafePal</p>
              <span>SafePal App</span>
            </div>
          </div>
        </div>
      </ModalStyled>
      {metaMaskState && <MetaMaskWallet setMetaMaskWallet={setMetaMaskWallet} />}
      {walletConnectState && <WalletConnect setWalletConnectState={setWalletConnectState} />}
      {binanceWallet && <BinanceWallet setBinanceWallet={setBinanceWallet} />}
      {mathWalletState && <MathWallet setMathWallet={setMathWallet} />}
      {coinbaseState && <CoinBaseWallet setCoinbaseWallet={setCoinbaseWallet} />}
      {safePalState && <SafePalWallet setSafePalWallet={setSafePalWallet} />}
    </>
  );
};

const DownloadConnect = ({ setConnectModal }) => {
  return (
    <>
      <BackDrop onClick={() => setConnectModal(false)} />
      <DownloadModalStyled>
        <div className="connect-header">
          <h5>Connect Wallet</h5>
          <span>&times;</span>
        </div>
        <div className="content">
          <div className="wallets-wrapper">
            <div className="wallet-box">
              <MetaMask />
              <p>MetaMask</p>
            </div>
            <div className="wallet-box">
              <img src={binanceConnect} alt="binance-connect" />
              <p>Binance Wallet</p>
            </div>
            <div className="wallet-box">
              <img src={coinbase} alt="trustWallet" />
              <p>Coinbase Wallet</p>
            </div>
            <div className="wallet-box" style={{ justifyContent: "flex-end" }}>
              <img src={MoreImg} alt="more" style={{ marginBottom: "20px" }} />
              <p>More</p>
            </div>
          </div>
        </div>
        <div className="connect-footer">
          <p>Haven't got a crypto wallet yet?</p>
          <button type="button">Learn how to connect</button>
        </div>
      </DownloadModalStyled>
    </>
  )
}

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
const DownloadModalStyled = styled.div`
  .connect-header {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    h5 {
      font-size: 18px;
      font-family: "source-code-roman";
      font-weight: 700;
    }
    span {
      font-size: 30px;
      color: #82dae2;
      cursor: pointer;
    }
  }
  .connect-footer {
    text-align: center;
    margin-top: 5px;
    padding: 15px 15px 25px;
    p {
      font-size: 14px;
      color: #796eaa;
      font-weight: 600;
    }
    button {
      background-color: #796eaa;
      color: #fff;
      margin-top: 17px;
      width: 90%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      border-radius: 15px;
      border: 0;
      font-weight: 700;
      font-family: inherit;
      font-size: 15px;
      line-height: 1;
      cursor: pointer;
      transition: 0.3s ease-in-out opacity;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  position: fixed;
  inset: 55% auto auto 50%;
  border: none;
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 25px;
  outline: none;
  padding: 0px;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0px;
  max-width: 400px;
  min-width: fit-content;
  max-height: 100%;
  z-index: 1000;
  .content {
    width: 100%;
    height: 100%;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    position: relative;
    .wallets-wrapper {
      background-color: #fff;
      border-radius: 12px;
      margin: 0;
      padding: 0;
      opacity: 1;
      text-align: center;
      visibility: visible;
      pointer-events: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 100%;
      min-width: fit-content;
      max-height: 100%;
      overflow: auto;
      border: none;
      .wallet-box {
        width: 100%;
        height: 100%;
        padding: 24px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        border-radius: 0;
        &:hover {
          background-color: hsla(0, 0%, 76.5%, 0.14);
        }
        img,
        svg {
          width: 50px;
          height: auto;
        }
        p {
          width: 100%;
          font-size: 14px;
          font-weight: 700;
          margin-top: 0.5em;
          color: #9790a5;
        }
        span {
          width: 100%;
          font-size: 18px;
          margin: 0.333em 0;
          color: #9790a5;
        }
      }
    }
  }
`;

const ModalStyled = styled.div`
  .connect-header {
    @media (max-width: 767px) {
      display: flex;
    }
    @media (min-width: 768px) {
      display: none;
    }
    background-color: white;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 15px;
    h5 {
      font-size: 18px;
      font-family: "source-code-roman";
      font-weight: 700;
    }
    span {
      font-size: 30px;
      color: #82dae2;
      cursor: pointer;
    }
  }
  position: fixed;
  inset: 55% auto auto 50%;
  border: none;
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 12px;
  outline: none;
  padding: 0px;
  transform: translate(-50%, -54%);
  width: 100%;
  margin: 0px;
  max-width: 620px;
  font-family: "proxima-nova",sans-serif;
  min-width: fit-content;
  max-height: 100%;
  z-index: 1000;
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    .wallets-wrapper {
      background-color: #fff;
      border-radius: 12px;
      margin: 0;
      padding: 0;
      opacity: 1;
      text-align: center;
      visibility: visible;
      pointer-events: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 100%;
      min-width: fit-content;
      max-height: 100%;
      overflow: auto;
      border: none;
      .wallet-box {
        width: 100%;
        height: 100%;
        padding: 24px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        border-radius: 0;
        border: 1px solid hsla(0, 0%, 76.5%, 0.14);
        &:hover {
          background-color: hsla(0, 0%, 76.5%, 0.14);
        }
        img,
        svg {
          width: 50px;
          height: auto;
        }
        p {
          width: 100%;
          font-size: 21px;
          font-weight: 700;
          margin-top: 0.5em;
          color: #0c0c0d;
        }
        span {
          width: 100%;
          font-size: 14px;
          margin: 0.6em 0;
          color: #a9a9bc;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .wallet-box {
      padding: 20px 10px !important;
    }
    .content .wallets-wrapper .wallet-box p {
      font-size: 15px;
    }
    .content .wallets-wrapper .wallet-box span {
      font-size: 12px;
    }
  }
`;

export { ConnectModal, DownloadConnect };
